<template>
  <div class="productDetail">
    <PageTitle ttl-en="PRODUCT" ttl-jp="_製品紹介" />
    <div class="inner">
      <section class="productDetail__outline">
        <h1 class="productDetail__outline__ttl js-scroll">
          外観検査用LED照明製品
        </h1>
        <p class="productDetail__outline__txt js-scroll">
          欠陥や不良を発見しやすく生産性の高い検査照明を構築するためには、現場環境や検査目的に合った照明装置の導入が不可欠です。【GENBA
          SHIKO】では自動車の検査工程で使用するのに最適な、オリジナルの照明製品を開発・製造しています。照明のプロフェッショナルとして光の原理まで掘り下げて開発した製品の数々は、検査照明や光環境を大きく向上させ、現場の生産性を高めるのに貢献します。
        </p>
      </section>
      <div class="productDetail__lineup">
        <div class="productDetail__lineup__block js-scroll" id="item8">
          <p class="productDetail__lineup__name">
            ゼブライン
          </p>
          <p class="productDetail__lineup__img">
            <img
              src="/genbashiko/img/product/product02/image10.jpg"
              alt="ゼブライン"
            />
          </p>
          <p class="productDetail__lineup__ttl">
            ゼブラ検査のコスト削減・施工性向上
          </p>
          <p class="productDetail__lineup__txt">
            塗装外観検査に特化した照明、ルーチ・ゼブライン。ライティングレール給電を採用したことで一点留め取付が可能。照明の取り付け・取り外し作業が工具なしで行う事ができ、施工費用を最大25%削減します（※当社比）。また、施工後の照明間ピッチも自在に変更可能ですのでレイアウト変更も容易に行えます。発光面は映り込み輝度差をハッキリとさせる検査に特化した設計で、塗装ブツの発見を手助けします。
            </p>
          <div class="productDetail__lineup__accordion">
            <button class="productDetail__lineup__accBtn" id="js-acc-btn">
              製品仕様・価格
            </button>
            <div class="productDetail__lineup__detail" id="js-acc-cts">
              <div class="spec">
                <p class="spec__ttl">
                  製品仕様
                </p>
                <dl>
                  <dt>入力電圧</dt>
                  <dd>DC24V</dd>
                </dl>
                <dl>
                  <dt>使用環境</dt>
                  <dd>屋内（結露なきこと）</dd>
                </dl>
                <dl>
                  <dt>材質（本体）</dt>
                  <dd>アルミニウム、ポリカーボネート</dd>
                </dl>
                <dl>
                  <dt>調光</dt>
                  <dd>可能</dd>
                </dl>
                <dl>
                  <dt>光源色（色温度）</dt>
                  <dd>N(5000K)</dd>
                </dl>
              </div>
              <div class="size">
                <p class="size__ttl">
                  サイズ・価格
                </p>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 0606</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥9,400（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>
                        W48*H38.2*D605.5㎜
                      </dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>8.2W/m</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>390g</dd>
                    </dl>
                    <dl>
                      <dt>消費効率</dt>
                      <dd>130lm/w</dd>
                    </dl>
                  </div>
                </div>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 1006</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥15,000円（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>
                        W48*H38.2*D1005.5㎜
                      </dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>13.6W</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>650g</dd>
                    </dl>
                    <dl>
                      <dt>消費効率</dt>
                      <dd>130lm/w</dd>
                    </dl>
                  </div>
                </div>
                <div class="size__block">
                  <p class="size__block__ttl">
                    サイズ 1207
                  </p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥17,800円（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>
                        W48*H38.2*D1206.5㎜
                      </dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>16.3W</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>780g</dd>
                    </dl>
                    <dl>
                      <dt>消費効率</dt>
                      <dd>130lm/w</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="productDetail__lineup__cate process">
            <p>本製品の利用工程：</p>
            <ul>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '塗装完成車工程' }}"
                >
                  塗装完成車工程
                </router-link>
              </li>
            </ul>
          </div>
          <div class="productDetail__lineup__cate case">
            <p>本製品の活用する想定課題：</p>
            <ul>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '新たに検査現場を構築したい' }}"
                >
                  新たに検査現場を構築したい
                </router-link>
              </li>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '既存の検査場を改修したい' }}"
                >
                  既存の検査場を改修したい
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="productDetail__lineup__block js-scroll" id="item1">
          <p class="productDetail__lineup__name">
            ルーチ・リタープ
          </p>
          <p class="productDetail__lineup__img">
            <img
              src="/genbashiko/img/product/product02/image01.jpg"
              alt="ルーチ・リタープ"
            />
          </p>
          <p class="productDetail__lineup__ttl">
            影が出にくい面発光で作業効率アップ
          </p>
          <p class="productDetail__lineup__txt">
            発光面が大きく、広範囲を高輝度で照らすことができるリタープ。全面から均一に光を拡散させることで、影が出にくくする特性をもつため、エンジンやパワートレインなどの凹凸のあるユニット部品の検査に最適です。演色性も高いため、自然光と同等の眩しくない明るい光環境で検査することで、不良の見逃し削減や作業者の疲労軽減につながります。しかもランプ部分のみの交換が可能なのでエコに配慮した製品です。
          </p>
          <a
            class="productDetail__lineup__txtlink"
            href="https://youtu.be/SS78S9EyGpE"
            target="_blank"
            >＞ 【日本語版】商品ムービーはこちら</a
          >
          <a
            class="productDetail__lineup__txtlink"
            href="https://youtu.be/n6SAx8gHQ6M"
            target="_blank"
            >＞ 【英語版】商品ムービーはこちら</a
          >
          <div class="productDetail__lineup__accordion">
            <button class="productDetail__lineup__accBtn" id="js-acc-btn">
              製品仕様・価格
            </button>
            <div class="productDetail__lineup__detail" id="js-acc-cts">
              <div class="spec">
                <p class="spec__ttl">
                  製品仕様
                </p>
                <dl>
                  <dt>入力電圧</dt>
                  <dd>AC100V</dd>
                </dl>
                <dl>
                  <dt>使用環境</dt>
                  <dd>屋内（結露無きこと）</dd>
                </dl>
                <dl>
                  <dt>材質（本体）</dt>
                  <dd>アルミニウム, アクリル</dd>
                </dl>
                <dl>
                  <dt>付属品</dt>
                  <dd>ルーチ・オリレガ145W専用電源, 調光器</dd>
                </dl>
                <dl>
                  <dt>調光</dt>
                  <dd>可能</dd>
                </dl>
                <dl>
                  <dt>光源色(色温度)</dt>
                  <dd>D（6500K）, N（5000K）</dd>
                </dl>
              </div>
              <div class="size">
                <p class="size__ttl">
                  サイズ・価格
                </p>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 0568-0420（A2）</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥198,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>W568*H72*D420 mm</dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>97.7W</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>6 kg（電源装置、調光器含む）</dd>
                    </dl>
                    <dl>
                      <dt>消費効率（Dの場合）</dt>
                      <dd>55.3 lm/W</dd>
                    </dl>
                  </div>
                </div>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 0568-0219（L1）</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥168,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>W568*H72.1*D219 mm</dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>48.9W</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>4.5 kg（電源装置、調光器含む）</dd>
                    </dl>
                    <dl>
                      <dt>消費効率（Dの場合）</dt>
                      <dd>55.3 lm/W</dd>
                    </dl>
                  </div>
                </div>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 0420-0298（A3）</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥168,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>W420*H72*D298 mm</dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>48.9W</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>4.5 kg（電源装置、調光器含む）</dd>
                    </dl>
                    <dl>
                      <dt>消費効率（Dの場合）</dt>
                      <dd>55.3 lm/W</dd>
                    </dl>
                  </div>
                </div>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 0298-0219（A4）</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥135,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>W298*H72.1*D219 mm</dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>24.4W</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>2.7 kg（電源装置、調光器含む）</dd>
                    </dl>
                    <dl>
                      <dt>消費効率（Dの場合）</dt>
                      <dd>55.3 lm/W</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="productDetail__lineup__cate process">
            <p>本製品の利用工程：</p>
            <ul>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: 'エンジン・パワートレイン工程' }}"
                >
                  エンジン・パワートレイン工程
                </router-link>
              </li>
            </ul>
          </div>
          <div class="productDetail__lineup__cate case">
            <p>本製品の活用する想定課題：</p>
            <ul>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '既存の検査場を改修したい' }}"
                >
                  既存の検査場を改修したい
                </router-link>
              </li>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '現状の課題を確認したい' }}"
                >
                  現状の課題を確認したい
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="productDetail__lineup__block js-scroll" id="item3">
          <p class="productDetail__lineup__name">
            ルーチ・SHIN UQ フレックス /
            <br>ルーチ・SHIN UQ フレックス α
          </p>
          <p class="productDetail__lineup__img">
            <img
              src="/genbashiko/img/product/product02/image08.jpg"
              alt="ルーチ・SHIN UQ フレックス / ルーチ・SHIN UQ フレックス α"
            />
          </p>
          <p class="productDetail__lineup__ttl">
            検査工程に応じた仕様で検査精度アップ
          </p>
          <p class="productDetail__lineup__txt">
            曲線やエッジのある、塗装完成検査工程の目視検査に最適なのがUQフレックス。50mm単位で最長2mまで、設置状況に応じた仕様でカスタムオーダーが可能なので、製品に適した確実な検査を行うことができます。
            また、曲線やエッジのあるワーク表面にゼブラの映り込みを作る事ができるので、目視検査で見つけづらい曲線部品の凹凸欠陥を認識しやすく、検査精度を上げることが可能です。
            さらに、光の継ぎ目のない連結ができるので、キズやブツを発見する検出率の向上も期待できます。
          </p>
          <div class="productDetail__lineup__accordion">
            <button class="productDetail__lineup__accBtn" id="js-acc-btn">
              製品仕様・価格
            </button>
            <div class="productDetail__lineup__detail" id="js-acc-cts">
              <div class="spec">
                <p class="spec__ttl">
                  製品仕様
                </p>
                <dl>
                  <dt>入力電圧</dt>
                  <dd>DC24V</dd>
                </dl>
                <dl>
                  <dt>使用環境</dt>
                  <dd>屋外（結露無きこと）</dd>
                </dl>
                <dl>
                  <dt>最大直列連結数</dt>
                  <dd>10m</dd>
                </dl>
                <dl>
                  <dt>材質（本体）</dt>
                  <dd>シリコン</dd>
                </dl>
                <dl>
                  <dt>調光</dt>
                  <dd>可能</dd>
                </dl>
                <dl>
                  <dt>光源色（色温度）</dt>
                  <dd>N（5300K）, L27（2700K）</dd>
                </dl>
              </div>
              <div class="size">
                <p class="size__ttl">
                  サイズ・価格
                </p>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 0050-2000</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>外形寸法（1mの場合）</dt>
                      <dd>W1006~1022（リード線仕様による）*H15*D16 mm
                        <p class="note">※2mまで50mmごとに長さ設定が可能</p>
                      </dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>14.9 W/m</dd>
                    </dl>
                    <dl>
                      <dt>消費効率（L27の場合）</dt>
                      <dd class="fs135">SHIN UQ フレックス 77.8 lm/W
                      <br>SHIN UQ フレックス α 62.6 lm/W</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="productDetail__lineup__cate process">
            <p>本製品の利用工程：</p>
            <ul>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '塗装完成車工程' }}"
                >
                  塗装完成車工程
                </router-link>
              </li>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: 'ボデー・プレス工程' }}"
                >
                  ボデー・プレス工程
                </router-link>
              </li>
            </ul>
          </div>
          <div class="productDetail__lineup__cate case">
            <p>本製品の活用する想定課題：</p>
            <ul>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '既存の検査場を改修したい' }}"
                >
                  既存の検査場を改修したい
                </router-link>
              </li>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '新たに検査現場を構築したい' }}"
                >
                  新たに検査現場を構築したい
                </router-link>
              </li>
              <li></li>
            </ul>
          </div>
        </div>
        <div class="productDetail__lineup__block js-scroll" id="item5">
          <p class="productDetail__lineup__name">
            ルーチ・トフ 特注品
          </p>
          <p class="productDetail__lineup__img">
            <img src="/genbashiko/img/product/product02/image05.jpg" alt="ルーチ・トフ 特注品" />
          </p>
          <p class="productDetail__lineup__ttl">
            1台で各塗装色に合った明るさを再現
          </p>
          <p class="productDetail__lineup__txt">
            塗装後検査工程で各塗装色に合わせた調色調光を自動化するのに最適なのがルーチ・トフ。これ1台で、2700Kから5000Kまでお好みの色温度の設定が可能なので、検査対象に合わせた調色調光プログラムができるのが特長です。ライン照明として使用する際には、継ぎ目を発生させずに連結できるので、見落としを減らし、キズやブツを発見する検出率向上が期待できます。また、スクエア形状を生かして直角に組み合わせるこも可能です。
          </p>
          <a
            class="productDetail__lineup__txtlink"
            href="https://youtu.be/Ifl1AAtcZug"
            target="_blank"
            >＞ 【日本語版】商品ムービーはこちら</a
          >
          <a
            class="productDetail__lineup__txtlink"
            href="https://youtu.be/t_jt-j0T60U"
            target="_blank"
            >＞ 【英語版】商品ムービーはこちら</a
          >
          <div class="productDetail__lineup__accordion">
            <button class="productDetail__lineup__accBtn" id="js-acc-btn">
              製品仕様・価格
            </button>
            <div class="productDetail__lineup__detail" id="js-acc-cts">
              <div class="spec">
                <p class="spec__ttl">
                  製品仕様
                </p>
                <dl>
                  <dt>入力電圧</dt>
                  <dd>DC24V</dd>
                </dl>
                <dl>
                  <dt>使用環境</dt>
                  <dd>屋内（結露無きこと）</dd>
                </dl>
                <dl>
                  <dt>最大直列連結数</dt>
                  <dd>4m</dd>
                </dl>
                <dl>
                  <dt>材質（本体）</dt>
                  <dd>ポリカーボネート・アルミニウム</dd>
                </dl>
                <dl>
                  <dt>付属品</dt>
                  <dd>取付パーツ 2個</dd>
                </dl>
                <dl>
                  <dt>調光</dt>
                  <dd>可能</dd>
                </dl>
                <dl>
                  <dt>光源色（色温度）</dt>
                  <dd class="fs13">下記2色をチョイス
                      <br>N（5000K）, W（4000K）, WW（3500K）, L30（3000K）, L27（2700K）, L24（2400K）</dd>
                </dl>
              </div>
              <div class="size">
                <p class="size__ttl">
                  サイズ・価格
                </p>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 0100</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥5,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>W100±2*H39*D24 mm</dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>2.8W（最大）</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>50g</dd>
                    </dl>
                    <dl>
                      <dt>消費効率</dt>
                      <dd>76.1 lm/W</dd>
                    </dl>
                  </div>
                </div>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 0500</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥16,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>W500±2*H39*D24 mm</dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>13.9W（最大）</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>210g</dd>
                    </dl>
                    <dl>
                      <dt>消費効率</dt>
                      <dd>76.1 lm/W</dd>
                    </dl>
                  </div>
                </div>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 1000</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥32,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>W1000±2*H39*D24 mm</dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>27.8W（最大）</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>400g</dd>
                    </dl>
                    <dl>
                      <dt>消費効率</dt>
                      <dd>76.1 lm/W</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="productDetail__lineup__cate process">
            <p>本製品の利用工程：</p>
            <ul>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '塗装完成車工程' }}"
                >
                  塗装完成車工程
                </router-link>
              </li>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: 'ボデー・プレス工程' }}"
                >
                  ボデー・プレス工程
                </router-link>
              </li>
            </ul>
          </div>
          <div class="productDetail__lineup__cate case">
            <p>本製品の活用する想定課題：</p>
            <ul>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '既存の検査場を改修したい' }}"
                >
                  既存の検査場を改修したい
                </router-link>
              </li>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '検査現場を省人化したい' }}"
                >
                  検査現場を省人化したい
                </router-link>
              </li>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '新たに検査現場を構築したい' }}"
                >
                  新たに検査現場を構築したい
                </router-link>
              </li>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '現状の課題を確認したい' }}"
                >
                  現状の課題を確認したい
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="productDetail__lineup__block js-scroll" id="item6">
          <p class="productDetail__lineup__name">
            ルーチ・トフタ
          </p>
          <p class="productDetail__lineup__img">
            <img
              src="/genbashiko/img/product/product02/image06.jpg"
              alt="ルーチ・トフタ"
            />
          </p>
          <p class="productDetail__lineup__ttl">
            検査対象に合った色温度を設定可能
          </p>
          <p class="productDetail__lineup__txt">
            全6色の豊富な色温度バリエーションがあり、検査対象によってベストな色温度に選べるのが特長です。状況に応じて明るさを調整することも可能なので、ワークの色や反射光の強さ、検査者・作業員の好みなどに合わせ明るさを変えて目視検査するのに最適です。ライン照明として使用する際には、継ぎ目を発生させずに連結できるので、見落としを減らし、キズやブツを発見する検出率向上が期待できます。また、スクエア形状を生かして直角に組み合わせるこも可能です。
          </p>
          <a
            class="productDetail__lineup__txtlink"
            href="https://youtu.be/Ifl1AAtcZug"
            target="_blank"
            >＞ 【日本語版】商品ムービーはこちら</a
          >
          <a
            class="productDetail__lineup__txtlink"
            href="https://youtu.be/t_jt-j0T60U"
            target="_blank"
            >＞ 【英語版】商品ムービーはこちら</a
          >
          <div class="productDetail__lineup__accordion">
            <button class="productDetail__lineup__accBtn" id="js-acc-btn">
              製品仕様・価格
            </button>
            <div class="productDetail__lineup__detail" id="js-acc-cts">
              <div class="spec">
                <p class="spec__ttl">
                  製品仕様
                </p>
                <dl>
                  <dt>入力電圧</dt>
                  <dd>DC24V</dd>
                </dl>
                <dl>
                  <dt>使用環境</dt>
                  <dd>屋内（結露無きこと）</dd>
                </dl>
                <dl>
                  <dt>最大直列連結数</dt>
                  <dd>5m</dd>
                </dl>
                <dl>
                  <dt>材質（本体）</dt>
                  <dd>ポリカーボネート・アルミニウム</dd>
                </dl>
                <dl>
                  <dt>付属品</dt>
                  <dd>取付パーツ 2個</dd>
                </dl>
                <dl>
                  <dt>調光</dt>
                  <dd>可能</dd>
                </dl>
                <dl>
                  <dt>光源色（色温度）</dt>
                  <dd>
                    N（5000K）, W（4000K）, WW（3500K）, L30（3000K）,
                    L27（2700K）, L24（2400K）
                  </dd>
                </dl>
              </div>
              <div class="size">
                <p class="size__ttl">
                  サイズ・価格
                </p>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 0103</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥5,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>W103±2*H39*D24 mm</dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>2.1W</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>50g</dd>
                    </dl>
                    <dl>
                      <dt>消費効率（L27の場合）</dt>
                      <dd>68.3 lm/W</dd>
                    </dl>
                  </div>
                </div>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 0306</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥9,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>W306±2*H39*D24 mm</dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>6.4W</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>132g</dd>
                    </dl>
                    <dl>
                      <dt>消費効率（L27の場合）</dt>
                      <dd>68.3 lm/W</dd>
                    </dl>
                  </div>
                </div>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 0503</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥12,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>W503±2*H39*D24 mm</dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>10.6W</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>220g</dd>
                    </dl>
                    <dl>
                      <dt>消費効率（L27の場合）</dt>
                      <dd>68.3 lm/W</dd>
                    </dl>
                  </div>
                </div>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 1003</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥24,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>W1003±2*H39*D24 mm</dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>21.3W</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>420g</dd>
                    </dl>
                    <dl>
                      <dt>消費効率（L27の場合）</dt>
                      <dd>68.3 lm/W</dd>
                    </dl>
                  </div>
                </div>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 1205</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥28,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>W1205±2*H39*D24 mm</dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>25.6W</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>505g</dd>
                    </dl>
                    <dl>
                      <dt>消費効率（L27の場合）</dt>
                      <dd>68.3 lm/W</dd>
                    </dl>
                  </div>
                </div>
                <div class="size__block">
                  <p class="size__block__ttl">サイズ 1503</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥33,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>W1503±2*H39*D24 mm</dd>
                    </dl>
                    <dl>
                      <dt>消費電力</dt>
                      <dd>31.9W</dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>620g</dd>
                    </dl>
                    <dl>
                      <dt>消費効率（L27の場合）</dt>
                      <dd>68.3 lm/W</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="productDetail__lineup__cate process">
            <p>本製品の利用工程：</p>
            <ul>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '塗装完成車工程' }}"
                >
                  塗装完成車工程
                </router-link>
              </li>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: 'ボデー・プレス工程' }}"
                >
                  ボデー・プレス工程
                </router-link>
              </li>
            </ul>
          </div>
          <div class="productDetail__lineup__cate case">
            <p>本製品の活用する想定課題：</p>
            <ul>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '既存の検査場を改修したい' }}"
                >
                  既存の検査場を改修したい
                </router-link>
              </li>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '検査現場を省人化したい' }}"
                >
                  検査現場を省人化したい
                </router-link>
              </li>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '新たに検査現場を構築したい' }}"
                >
                  新たに検査現場を構築したい
                </router-link>
              </li>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '現状の課題を確認したい' }}"
                >
                  現状の課題を確認したい
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="productDetail__lineup__block js-scroll" id="item7">
          <p class="productDetail__lineup__name">
            ゼブラスタンド
          </p>
          <p class="productDetail__lineup__img">
            <img
              src="/genbashiko/img/product/product02/image09.jpg"
              alt="ゼブラスタンド"
            />
          </p>
          <p class="productDetail__lineup__ttl">
            コンセントに指すだけのゼブラ検査キット
          </p>
          <p class="productDetail__lineup__txt">
            コンセントに差すだけで簡単に設置できるゼブラスタンド。ゼブラスタンドに搭載している照明はスクエア型のLED照明なので、従来の蛍光灯や円筒形LEDとは異なり、車体に映り込んだ光のライン(境目)がくっきりとして見えるため、ゼブラ効果で明暗の境界線上に浮かび上がるキズや凹みを発見しやすいのが特長です。また、移動に便利なキャスター仕様なので完成車検査やボデー工程検査に最適です。
          </p>
          <div class="productDetail__lineup__accordion">
            <button class="productDetail__lineup__accBtn" id="js-acc-btn">
              製品仕様・価格
            </button>
            <div class="productDetail__lineup__detail" id="js-acc-cts">
              <div class="spec">
                <p class="spec__ttl">
                  製品仕様
                </p>
                <dl>
                  <dt>入力電圧</dt>
                  <dd>AC100V</dd>
                </dl>
                <dl>
                  <dt>調光</dt>
                  <dd>可能</dd>
                </dl>
                <dl>
                  <dt>光源色(色温度)</dt>
                  <dd>N（5000K）, L27（2700K）</dd>
                </dl>
              </div>
              <div class="size">
                <p class="size__ttl">
                  タイプ・価格
                </p>
                <div class="size__block">
                  <p class="size__block__ttl">単色調光／昼白色（5000K） 5本</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥198,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>
                        W600mm*D450mm*H1203mm<br /><span
                          >※灯具サイズ（全タイプ共通）： W24mm × H39mm ×
                          D1003mm</span
                        >
                      </dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>15kg</dd>
                    </dl>
                  </div>
                </div>
                <div class="size__block">
                  <p class="size__block__ttl">単色調光／電球色（2700K） 5本</p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥198,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>
                        W600mm*D450mm*H1203mm<br /><span
                          >※灯具サイズ（全タイプ共通）： W24mm × H39mm × D1003
                          mm</span
                        >
                      </dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>15kg</dd>
                    </dl>
                  </div>
                </div>
                <div class="size__block">
                  <p class="size__block__ttl">
                    単色調光／昼白色（5000K）2本・電球色（2700K）3本
                  </p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥198,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>
                        W600mm*D450mm*H1203mm<br /><span
                          >※灯具サイズ（全タイプ共通）： W24mm × H39mm × D1003
                          mm</span
                        >
                      </dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>15kg</dd>
                    </dl>
                  </div>
                </div>
                <div class="size__block">
                  <p class="size__block__ttl">
                    調色調光／昼白色（5000K）～電球色（2700K）4本
                  </p>
                  <div class="size__block__inner">
                    <dl>
                      <dt>価格</dt>
                      <dd>￥198,000（税抜）</dd>
                    </dl>
                    <dl>
                      <dt>外形寸法</dt>
                      <dd>
                        W600mm*D450mm*H1203mm<br /><span
                          >※灯具サイズ（全タイプ共通）： W24mm × H39mm × D1003
                          mm</span
                        >
                      </dd>
                    </dl>
                    <dl>
                      <dt>質量</dt>
                      <dd>15kg</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="productDetail__lineup__cate process">
            <p>本製品の利用工程：</p>
            <ul>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '塗装完成車工程' }}"
                >
                  塗装完成車工程
                </router-link>
              </li>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: 'ボデー・プレス工程' }}"
                >
                  ボデー・プレス工程
                </router-link>
              </li>
            </ul>
          </div>
          <div class="productDetail__lineup__cate case">
            <p>本製品の活用する想定課題：</p>
            <ul>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '新たに検査現場を構築したい' }}"
                >
                  新たに検査現場を構築したい
                </router-link>
              </li>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '既存の検査場を改修したい' }}"
                >
                  既存の検査場を改修したい
                </router-link>
              </li>
              <li>
                <router-link
                  class="category"
                  :to="{ name: 'Case', query: { category: '現状の課題を確認したい' }}"
                >
                  現状の課題を確認したい
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="js-scroll">
          <ReturnToIndexBtn path="/product" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle.vue'
import ReturnToIndexBtn from '@/components/ReturnToIndexBtn.vue'
import scrollAnimMixin from '../../mixins/scrollAnimMixin.js'
import gsap from 'gsap'

export default {
  components: {
    PageTitle,
    ReturnToIndexBtn
  },
  mixins: [scrollAnimMixin],
  head: {
    title: {
      inner: '外観検査用LED照明製品 | 製品紹介'
    },
    meta: [
      {
        property: 'og:title',
        content: '外観検査用LED照明製品 | 製品紹介 | GENBA SHIKO'
      },
      {
        name: 'description',
        content:
          '自動車製造工場の検査工程で使用するのに最適な当社の照明製品をご覧ください。自動車産業の様々な課題に対して、車体品質の確保をサポートします。'
      },
      {
        property: 'og:description',
        content:
          '自動車製造工場の検査工程で使用するのに最適な当社の照明製品をご覧ください。自動車産業の様々な課題に対して、車体品質の確保をサポートします。'
      },
      {
        property: 'og:url',
        content: 'https://luci.co.jp/genbashiko/product/product02'
      },
      { property: 'og:type', content: 'article' }
    ]
  },
  mounted: function () {
    this.setAccBtnEvent()
  },
  methods: {
    setAccBtnEvent: function () {
      let accBtns = document.querySelectorAll('#js-acc-btn')
      accBtns.forEach(v => {
        v.addEventListener('click', e => {
          let parent = e.target.parentNode
          let detail = parent.querySelector('#js-acc-cts')
          if (parent.classList.contains('open')) {
            parent.classList.remove('open')
            gsap.to(detail, 0.3, { height: 0 })
          } else {
            parent.classList.add('open')
            gsap.to(detail, 0.3, { height: 'auto' })
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.productDetail {
  padding-bottom: 42px;
  &__outline {
    margin-top: 80px;
    &__ttl {
      font-size: 3.6rem;
      &::after {
        content: '';
        display: block;
        background: $orColor;
        width: 40px;
        height: 4px;
        margin: 30px auto 0;
      }
      &.js-scroll {
        transform: translateY(0);
      }
    }
    &__txt {
      line-height: 2.1;
      margin-top: 40px;
      font-size: 1.6rem;
    }
  }
  &__lineup {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    &__block {
      flex: 0 0 47%;
      max-width: 47%;
      border: 1px solid #3e3a39;
      padding: 40px;
      margin-top: 60px;
    }
    &__name {
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 1.45;
    }
    &__img {
      margin-top: 20px;
      text-align: center;
      // border:1px solid #cec9c3;
    }
    &__ttl {
      text-align: left;
      font-size: 2rem;
      margin-top: 30px;
    }
    &__txt {
      margin-top: 30px;
      line-height: 2.1;
    }
    &__imgList {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      flex-wrap: wrap;
      li {
        flex: 0 0 48.8%;
        margin-top: 20px;
        p {
          font-size: 1.4rem;
          margin-top: 20px;
          line-height: 1.84;
        }
      }
    }
    &__txtlink {
      font-size: 1.4rem;
      color: $orColor;
      font-weight: 700;
      display: block;
      margin-top: 15px;
      text-decoration: underline;
    }
    &__accordion {
      &.open {
        .productDetail__lineup__accBtn {
          &::after {
            transform: rotate(0deg);
          }
        }
      }
    }
    &__accBtn {
      position: relative;
      width: 225px;
      border: 1px solid $orColor;
      border-radius: 23px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $orColor;
      margin: 40px auto 0;
      transition: background-color 0.3s ease-out, color 0.3s ease-out;
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 13px;
        height: 1px;
        display: block;
        background: $orColor;
        top: 22px;
        right: 22px;
        transition: background-color 0.3s ease-out;
      }
      &::after {
        transition: transform 0.3s ease-out;
        transform: rotate(90deg);
      }
      &:hover {
        background-color: $orColor;
        color: #fff;
        &::before,
        &::after {
          background-color: #fff;
        }
      }
    }
    &__detail {
      height: 0;
      overflow: hidden;
      .spec {
        padding-top: 60px;
        &__ttl {
          font-weight: 700;
          text-align: left;
          margin-bottom: 20px;
        }
        dl {
          border: 1px solid #f2f0ec;
          min-height: 40px;
          display: flex;
          + dl {
            margin-top: 2px;
          }
          .fs13{
              font-size: 1.3rem;
              line-height: 1.4;
            }
          dt {
            background: #f2f0ec;
            flex: 0 0 110px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            font-weight: 400;
          }
          dd {
            display: flex;
            align-items: center;
            padding: 5px 10px;
          }
        }
      }
      .size {
        margin-top: 40px;
        &__ttl {
          font-weight: 700;
          text-align: left;
          margin-bottom: 20px;
        }
        &__block {
          border: 1px solid #f2f0ec;
          &__inner {
            padding: 20px;
          }
          &__ttl {
            padding: 10px 10px;
            background: #f2f0ec;
            text-align: left;
            font-weight: 400;
          }
          dl {
            display: flex;
            align-items: flex-start;
            .fs135{
              font-size: 1.35rem;
            }
            dt {
              font-weight: 400;
              flex-shrink: 0;
              display: flex;
              align-items: center;
              &::after {
                content: '：';
              }
            }
            dd {
              span {
                font-size: 1.2rem;
              }
            }
            + dl {
              margin-top: 10px;
            }
          }
          .note {
            display: block;
            text-indent: -1em;
            margin-left: 1em;
            margin-top: 5px;
          }
          + .size__block {
            margin-top: 20px;
          }
        }
      }
    }
    &__cate {
      p {
        font-weight: bold;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
      }
      &.process {
        margin-top: 60px;
      }
      &.case {
      }
      + .productDetail__lineup__cate {
        margin-top: 40px;
      }
    }
  }
  .js-scroll {
    width: 100%;
  }
  .returnBtn {
    margin-top: 100px;
  }
  &__nav {
    margin-top: 100px;
    border-top: 1px solid #cec9c3;
    &__ttl {
      margin-top: 50px;
      text-align: left;
      span {
        font-size: 2.6rem;
        font-family: $Lato;
        margin-right: 10px;
      }
    }
    ul {
      margin-top: 50px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        flex: 0 0 47%;
        .ttl {
          font-size: 2.2rem;
          font-weight: bold;
          margin-top: 30px;
          line-height: 1.45;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .productDetail {
    padding-bottom: 10px;
    &__outline {
      margin-top: 40px;
      &__ttl {
        font-size: 2.1rem;
        &::after {
          margin-top: 20px;
        }
      }
      h3 {
        font-size: 1.6rem;
        margin-top: 30px;
      }
      &__txt {
        font-size: 1.2rem;
        margin-top: 20px;
        letter-spacing: 0;
      }
    }
    &__lineup {
      margin-top: 40px;
      display: block;
      &__block {
        padding: 30px 20px;
        max-width: 100%;
      }
      &__name {
        font-size: 1.2rem;
      }
      &__ttl {
        font-size: 1.2rem;
        margin-top: 25px;
      }
      &__txt {
        font-size: 1.1rem;
        margin-top: 20px;
      }
      &__imgList {
        margin-top: 10px;
        li {
          flex: 0 0 47.5%;
          p {
            font-size: 1rem;
            margin-top: 15px;
          }
        }
      }
      &__detail {
        font-size: 1.1rem;
        .spec {
          padding-top: 40px;
          &__ttl {
            margin-bottom: 20px;
          }
          dl {
            min-height: 30px;
            .fs13{
              font-size: 1.1rem;
            }
            dt {
              background: #f2f0ec;
              flex: 0 0 85px;
              padding-left: 8px;
              line-height: 1.1;
            }
            dd {
              padding-left: 5px;
            }
          }
        }
        .size {
          &__ttl {
            margin-bottom: 20px;
          }
          &__block {
            &__inner {
              padding: 15px;
            }
            &__ttl {
            }
            dl {
              .fs135{
              font-size: 1.1rem;
              }
              dt {
              }
              + dl {
                margin-top: 5px;
              }
              dd {
                span {
                  font-size: 1rem;
                }
              }
            }
            + .size__block {
              margin-top: 10px;
            }
          }
        }
      }
      &__cate {
        &.process {
          margin-top: 35px;
        }
        p {
          font-size: 1.2rem;
        }
        + .productDetail__lineup__cate {
          margin-top: 20px;
        }
      }
      &__txtlink {
        font-size: 1.2rem;
        margin-top: 10px;
      }
    }

    .returnBtn {
      margin-top: 50px;
    }
  }
}
</style>
